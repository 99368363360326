<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-break-point="960"
    app
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>

    <v-divider class="mb-1" />

    <v-list
      dense
      nav
    >
      <v-list-item>

<!--
        <div style="width: 100%; display: flex; align-items: center; justify-content: space-between">
            <div style="background-color:white; padding-top: 2px; padding-left: 2px; padding-right: 2px;">
              <img src="@/assets/bonsai.svg" style="height: 30px" />
            </div>
            <div>{{profile.title}}</div>
          </div>-->


        <v-list-item-avatar
          class="align-self-center"
          color="white"
          size="48"
        >
          <img
            v-cdn-src="'B_Logo_Green.svg'"
            style="height:35px"
          />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title
            class="display-1"
            v-text="profile.title"
          />
        </v-list-item-content>
        
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />

    <v-list
      expand
      nav
    >
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <template v-for="(item, i) in items">
        
        <!--<base-item-group
          v-if="item.children"
          :key="`group-${i}`"
          :item="item"
        >
          
        </base-item-group>-->

        <base-item
          :key="`item-${i}`"
          :item="item"
        />

        <base-item class="ml-3" v-for="(subitem, j) in item.children"
          :key="`item-${i}-${j}`"
          :item="subitem"
          >
        </base-item>

      </template>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>

    <template v-slot:append>
    <div style="width: 100%; padding-left: 5em; padding-right: 5em; padding-bottom: 2em;">

      <img style="width: 100%" v-cdn-src="'Bonsai_Logo_White.svg'" />
      </div>
      <!--
        <base-item
          :item="{
            title: $t('upgrade'),
            icon: 'mdi-package-up',
            to: '/upgrade',
          }"
        />
      -->
    </template>
  </v-navigation-drawer>
</template>

<script>
  // Utilities
  import {
    mapState,
  } from 'vuex'

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      ...mapState(['barColor', 'barImage']),
      drawer: {
        get () {
          return this.$store.getters.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      items() { 
        const menuItems = [
         /*{
            icon: 'mdi-home',
            title: this.$t('routes.home.title'),
            to: '/',
          },*/
          {
            icon: 'mdi-domain',
            title: this.$t('routes.business.title'),
            to: 'business',
          },
          {
            title: this.$t('routes.invoices.title'),
            icon: 'mdi-receipt',
            to: 'invoices',
          },
          {
            title: this.$t('routes.cashiers.title'),
            icon: 'mdi-account-group',
            to: 'cashiers',
          },
          {
            title: this.$t('routes.transactions.title'),
            icon: 'mdi-format-list-bulleted',
            to: 'transactions',
          },
          {
            title: this.$t('routes.integrations.title'),
            icon: 'mdi-puzzle',
            to: 'integrations',
          }
        ]

        if (this.$store.getters.brands && this.$store.getters.brands.length) {
          
          if (this.$store.getters.brands.find(p => p.acceptViaDashboard)) 
            menuItems.push(
            {
              title: this.$t('routes.accept.title'),
              icon: 'mdi-currency-eur',
              to: 'accept',
            })
        }

        return menuItems
      },
      user() {
        return this.$store.getters.user
      },
      profile () {
        return {
          avatar: true,
          title: `${this.user.firstName} ${this.user.lastName}`,
        }
      },
    },
    
  }
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
